import React, { useEffect, useRef, useState } from 'react';

import arcoIcon from '~/assets/arco-icon-header.svg';

import styles from './index.module.css';

interface NavBarProps {
  projectName: string;
  saveProject: (name?: string) => void;
  view: 'preview' | 'code' | 'prompt';
  setView: (view: 'preview' | 'code' | 'prompt') => void;
  onLogoClick: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ projectName, saveProject, view, setView, onLogoClick }) => {
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const [tempProjectName, setTempProjectName] = useState(projectName);
  const projectNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isEditingProjectName &&
        projectNameInputRef.current &&
        !projectNameInputRef.current.contains(event.target as Node)
      ) {
        setIsEditingProjectName(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingProjectName]);

  const handleProjectNameDoubleClick = () => {
    setIsEditingProjectName(true);
  };

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempProjectName(e.target.value);
  };

  const handleProjectNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveProject(tempProjectName);
      setIsEditingProjectName(false);
    }
  };

  const handleProjectNameBlur = () => {
    saveProject(tempProjectName);
    setIsEditingProjectName(false);
  };

  const handlePublish = () => {
    saveProject();
  };

  return (
    <nav className={styles.navBar}>
      <div className={styles.leftSection}>
        <img src={arcoIcon} alt="Arco" className={styles.logoImage} onClick={onLogoClick} />
        <div className={styles.projectNameContainer}>
          <span className={styles.projectNameSlash}>/</span>
          {isEditingProjectName ? (
            <input
              ref={projectNameInputRef}
              type="text"
              value={tempProjectName}
              onChange={handleProjectNameChange}
              onKeyDown={handleProjectNameKeyDown}
              onBlur={handleProjectNameBlur}
              className={styles.projectNameInput}
              autoFocus
            />
          ) : (
            <span className={styles.projectNameText} onDoubleClick={handleProjectNameDoubleClick}>
              {projectName || '-'}
            </span>
          )}
        </div>
      </div>
      <div className={styles.centerSection}>
        <div className={styles.viewToggle}>
          <button
            className={`${styles.toggleButton} ${view === 'preview' ? styles.active : ''}`}
            onClick={() => setView('preview')}
          >
            Preview
          </button>
          <button
            className={`${styles.toggleButton} ${view === 'code' ? styles.active : ''}`}
            onClick={() => setView('code')}
          >
            Code
          </button>
          <button
            className={`${styles.toggleButton} ${view === 'prompt' ? styles.active : ''}`}
            onClick={() => setView('prompt')}
          >
            Prompt
          </button>
        </div>
      </div>
      <div className={styles.rightSection}>
        <button className={styles.publishButton} onClick={handlePublish}>
          发布
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
