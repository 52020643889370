import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../assets/loading.json';

interface LoadingProps {
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({ size = 120 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ width: size, height: size }}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
};

export default Loading;
