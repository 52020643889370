import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectInfo } from '~/api/project';
import eyeIcon from '~/assets/homepage-list-item-eye.svg';
import forkIcon from '~/assets/homepage-list-item-fork.svg';
import likeIcon from '~/assets/homepage-list-item-like.svg';
import defaultCover from '~/assets/user-default-avatar.svg';

import EmptyState from '../EmptyState';

import styles from './index.module.css';

interface ItemListProps {
  items: ProjectInfo[];
  handleFork: (projectId?: string) => void;
}

const ItemList: React.FC<ItemListProps> = ({ items, handleFork }) => {
  const navigate = useNavigate();

  const handleItemClick = useCallback(
    (projectId: string) => {
      navigate(`/project/${projectId}`);
    },
    [navigate]
  );

  if (items.length === 0) {
    return <EmptyState message="当前分类下暂无项目" />;
  }

  return (
    <div className={styles.listContainer}>
      {items.map((item) => (
        <div className={styles.listItem} key={item.project_id} onClick={() => handleItemClick(item.project_id || '')}>
          <div className={styles.itemPreview}>
            {item?.cover_url ? (
              <img className={styles.itemCover} src={item?.cover_url} alt="" />
            ) : (
              <iframe srcDoc={item.code} className={styles.itemIframe} title={item.project_id} />
            )}
            <div className={styles.itemHover}>
              <div className={styles.previewContent}>
                <div className={styles.itemPreviewTitle}>{item.project_title}</div>
                <div className={styles.forkButton} onClick={() => handleFork(item.project_id)}>
                  <img src={forkIcon} alt="fork" className={styles.forkIcon} />
                  Fork
                </div>
              </div>
            </div>
          </div>
          <div className={styles.itemInfo}>
            <div className={styles.itemContent}>
              <img src={item.user_info?.avatar || defaultCover} alt={item.project_title} className={styles.userIcon} />
              <div className={styles.itemTitle}>{item.project_title}</div>
            </div>
            <div className={styles.itemStats}>
              <div className={styles.statItem}>
                <img src={eyeIcon} alt="eye" className={styles.statIcon} />
                <div className={styles.statValue}>{item.interactive_info?.fork_count || 0}</div>
              </div>
              <div className={styles.statItem}>
                <img src={likeIcon} alt="like" className={styles.statIcon} />
                <div className={styles.statValue}>{item.interactive_info?.like_count || 0}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemList;
