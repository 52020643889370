import request from './index';
import OSS from 'ali-oss';

// 新增获取上传token的请求参数接口
interface GetUploadTokenParams {
  file_type: string;
  file_name: string;
}

// 新增获取上传token的响应数据接口
interface GetUploadTokenData {
  endpoint: string;
  bucket_name: string;
  access_key_id: string;
  access_key_secret: string;
  security_token: string;
  file_uri: string;
}

/**
 * 获取上传token
 * @param params - 获取上传token的参数
 * @returns 上传token相关信息
 */
export async function getUploadToken(params: GetUploadTokenParams): Promise<GetUploadTokenData> {
  return request<GetUploadTokenData>('/api/tools/upload_token', {
    method: 'POST',
    data: params,
  });
}

// 新的文件上传参数接口
interface OSSUploadParams {
  file: File;
  tokenParams: GetUploadTokenData;
  onProgress?: (progress: number) => void;
  signal?: AbortSignal;
}

// 新的文件上传结果接口
interface OSSUploadResult {
  url: string;
}

/**
 * 使用阿里云OSS上传文件
 * @param params - OSS文件上传参数
 * @returns 上传后的文件URL和ETag
 */
export async function uploadFileToOSS(params: OSSUploadParams): Promise<OSSUploadResult> {
  const { file, tokenParams } = params;

  console.log('Starting upload to OSS with params:', params);

  const client = new OSS({
    region: 'oss-cn-hangzhou', // 根据实际情况修改地域
    accessKeyId: tokenParams.access_key_id,
    accessKeySecret: tokenParams.access_key_secret,
    stsToken: tokenParams.security_token,
    bucket: tokenParams.bucket_name,
    endpoint: tokenParams.endpoint,
  });

  try {
    const result = await client.put(tokenParams.file_uri, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    console.log('oss uploaded successfully:', result);

    return {
      url: tokenParams.file_uri,
    };
  } catch (error) {
    console.error('OSS upload failed:', error);
    throw error;
  }
}

export async function uploadFileWithToken(file: File): Promise<OSSUploadResult> {
  try {
    console.log('Starting upload: ', file);
    // 获取上传凭证
    const params: GetUploadTokenParams = {
      file_type: file.type,
      file_name: file.name,
    };
    const tokenParams: GetUploadTokenData = await getUploadToken(params);

    // 上传文件到OSS
    const result = await uploadFileToOSS({ file, tokenParams });
    console.log('File uploaded successfully:', result);

    return {
      url: result.url,
    };
  } catch (error) {
    console.error('Upload with token failed:', error);
    throw error;
  }
}

