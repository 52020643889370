import { AUTH_CONSTANTS } from '~/constants';

export interface ApiResponse<T = any> {
  status_code?: number;
  message?: string;
  data: T;
}

type RequestOptions = Omit<RequestInit, 'body'> & {
  data?: Record<string, any>;
};

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.arcoer.com' : '';

const request = async <T>(url: string, options: RequestOptions = {}): Promise<T> => {
  const { data, method = 'GET', headers: initialHeaders = {}, ...fetchOptions } = options;
  const headers: Record<string, string> = { ...(initialHeaders as Record<string, string>) };

  let updatedUrl = BASE_URL + url;
  let body: string | FormData | undefined;

  if (data || {}) {
    if (method.toUpperCase() === 'GET') {
      // 对于 GET 请求，将 data 转换为查询参数
      const queryString = new URLSearchParams(data as Record<string, string>).toString();
      updatedUrl += (updatedUrl.includes('?') ? '&' : '?') + queryString;
    } else if (data instanceof FormData) {
      // 如果 data 是 FormData，直接使用它
      body = data;
    } else {
      // 对于其他非 GET 请求，将 data 转换为 JSON
      body = JSON.stringify(data || {});
      headers['Content-Type'] = 'application/json';
    }
  }

  const token = localStorage.getItem(AUTH_CONSTANTS.TOKEN_KEY);
  if (token) {
    headers['acro-Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(updatedUrl, {
      ...fetchOptions,
      method,
      headers,
      body,
    });

    const resData: ApiResponse<T> = await response.json();

    if (resData.status_code === 0) {
      return resData.data;
    } else {
      throw new Error(resData.message || '请求失败');
    }
  } catch (error) {
    console.error('请求错误:', error);
    throw error;
  }
};

export default request;
