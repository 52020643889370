import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { uploadFileWithToken} from '~/api/upload';
import ic_search_file_n from '~/assets/ic_search_file_n.svg';
import AttachmentPopup from '../../pages/HomePage/AttachmentPopup';
import ic_send_d from '~/assets/ic_send_d.svg';
import ic_send_n from '~/assets/ic_send_n.svg';
import styles from './index.module.css';

const SearchInput: React.FC<{ value: string; onChange: (value: string) => void; onSend: () => void; onFileUpload: (url: string) => void }> = ({
    value,
    onChange,
    onSend,
    onFileUpload
  }) => {
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !event.nativeEvent.isComposing && value.trim()) {
        onSend();
      }
    };
  
    const handleAttachmentClick = useCallback(() => {
    //   setShowAttachmentPopup((prev) => !prev);
      imageInputRef.current?.click();
    }, []);
  
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, type: 'document' | 'image') => {
      setShowAttachmentPopup(false);
      const files = event.target.files;
      if (files && files.length > 0) {
        const firstFile = files[0];
        setSelectedFiles(prevFiles => [...prevFiles, firstFile]);
  
        // 触发图片上传
        try {
          const result = await uploadFileWithToken(firstFile);
          onFileUpload(result.url);
        } catch (error) {
          console.error('File upload failed:', error);
        }
      }
    };
  
    const handleRemoveFile = (index: number) => {
      setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
          setShowAttachmentPopup(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className={styles.inputSection}>
        <div className={styles.inputWrapper}>
          <div className={styles.attachmentContainer}>
            <div className={styles.iconWrapper}>
              <img src={ic_search_file_n} alt="附件" className={styles.attachmentIcon} onClick={handleAttachmentClick} />
            </div>
          </div>
          <input
            type="text"
            className={styles.input}
            placeholder="Start your creation..."
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <img
            src={value.trim() ? ic_send_n : ic_send_d}
            alt="发送"
            className={styles.sendIcon}
            aria-disabled={!value.trim()}
            onClick={() => value.trim() && onSend()}
          />
          <input
            type="file"
            ref={imageInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            multiple // 添加这一行
            onChange={(e) => handleFileChange(e, 'image')}
          />
        </div>
        {selectedFiles.length > 0 && (
          <div className={styles.filePreviewContainer}>
            {selectedFiles.map((file, index) => (
              <div key={index} className={styles.filePreviewItem}>
                <div className={styles.filePreviewImageWrapper}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className={styles.filePreviewImage}
                  />
                </div>
                <button
                  className={styles.removeFileButton}
                  onClick={() => handleRemoveFile(index)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  export default SearchInput;