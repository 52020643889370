import request from './index';

// 项目生成请求参数
interface ProjectGenerateParams {
  prompt: string;
  project_id?: string;
  sub_code?: string;
  file_uri?: string[]; // 新增 file_uri 参数
}

// 新增 ProjectInfo 接口
export interface ProjectInfo {
  project_id?: string;
  project_title?: string;
  code?: string;
  cover_url?: string;
  collection?: {
    collection_id: number;
    collection_name: string;
  };
  user_info: {
    avatar: string;
    id: number;
    user_name: string;
  };
  interactive_info: {
    fork_count: number;
    like_count: number;
  };
}

// 项目生成响应数据
interface ProjectGenerateData extends ProjectInfo {}

// 项目列表响应数据
interface ProjectListData {
  data_list: ProjectInfo[];
}

// 项目详情请求参数
interface ProjectDetailParams {
  project_id: string;
}

// 项目详情响应数据
interface ProjectDetailData extends ProjectInfo {}

// 分类列表请求参数
interface CollectionListParams {
  collection_id?: number;
}

// 分类列表响应数据
interface CollectionListData {
  data_list: Array<{
    collection_id: number;
    collection_name: string;
  }>;
}

// 项目更新请求参数
interface ProjectUpdateParams {
  project_id: string;
  code?: string;
  project_title?: string;
  cover?: string;
}

// 项目更新响应数据
interface ProjectUpdateData {
  project_id?: string;
}

// 项目历史详情请求参数
interface ProjectHistoryParams {
  project_id?: string;
}

// 项目历史详情响应数据
interface ProjectHistoryData {
  data_list: Array<{
    user_prompt: string;
    create_at: number;
  }>;
}

/**
 * 生成项目
 * @param params - 项目生成参数
 * @returns 生成的项目信息
 */
export async function generateProject(params: ProjectGenerateParams): Promise<ProjectGenerateData> {
  return request<ProjectGenerateData>('/api/project_generate', {
    method: 'POST',
    data: params,
  });
}

/**
 * 获取项目列表
 * @returns 项目列表数据
 */
export async function getProjectList(): Promise<ProjectListData> {
  return request<ProjectListData>('/api/project_list', {
    method: 'POST',
    data: {},
  });
}

/**
 * 获取项目详情
 * @param params - 项目详情请求参数
 * @returns 项目详细信息
 */
export async function getProjectDetail(params: ProjectDetailParams): Promise<ProjectDetailData> {
  return request<ProjectDetailData>('/api/project_detail', {
    method: 'POST',
    data: params,
  });
}

/**
 * 获取分类列表
 * @param params - 分类列表请求参数
 * @returns 分类列表数据
 */
export async function getCollectionList(params: CollectionListParams): Promise<CollectionListData> {
  return request<CollectionListData>('/api/collection_list', {
    method: 'POST',
    data: params,
  });
}

/**
 * 更新项目
 * @param params - 项目更新参数
 * @returns 更新后的项目信息
 */
export async function updateProject(params: ProjectUpdateParams): Promise<ProjectUpdateData> {
  return request<ProjectUpdateData>('/api/project_update', {
    method: 'POST',
    data: params,
  });
}

/**
 * 获取项目历史详情
 * @param params - 项目历史详情请求参数
 * @returns 项目历史详情数据
 */
export async function getProjectHistory(params: ProjectHistoryParams): Promise<ProjectHistoryData> {
  return request<ProjectHistoryData>('/api/project_history', {
    method: 'POST',
    data: params,
  });
}
