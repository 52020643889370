export const GoogleLoginUrl =
  `https://accounts.google.com/o/oauth2/v2/auth?` +
  `client_id=355123857808-4o762plud96levcdq7j9qrgqf9qp5o2u.apps.googleusercontent.com&` +
  `scope=${encodeURIComponent(
    'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'
  )}&` +
  `include_granted_scopes=true&` +
  `redirect_uri=${encodeURIComponent(`${window.location.origin}/auth/callback/google`)}&` +
  `response_type=code`;

export const DiscordLoginUrl =
  `https://discord.com/oauth2/authorize?` +
  `client_id=1278312816356364288&` +
  `scope=identify+email+guilds.join+guilds.members.read+role_connections.write&` +
  `redirect_uri=${encodeURIComponent(`${window.location.origin}/auth/callback/discord`)}&` +
  `response_type=code`;
