import html2canvas from 'html2canvas';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { generateProject, getProjectDetail, getProjectHistory, updateProject } from '~/api/project';
import { uploadFileWithToken } from '~/api/upload';
import Loading from '~/components/Loading';

import EditorPreview from './EditorPreview';
import InputArea from './InputArea';
import NavBar from './NavBar';
import styles from './index.module.css';

const ProjectPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get('message');
  const files = searchParams.get('files');
  const navigate = useNavigate();

  const [view, setView] = useState<'preview' | 'code' | 'prompt'>('preview');
  const [code, setCode] = useState('<h1>Hello World!</h1>');
  const [projectName, setProjectName] = useState('新创作');
  const [isLoading, setIsLoading] = useState(false);
  const initializeProjectCalled = useRef(false);
  const [projectHistory, setProjectHistory] = useState<Array<{ user_prompt: string; create_at: number }>>([]);

  const getCoverUri = useCallback((): Promise<string> => {
    setView('preview');
    return new Promise(async (resolve) => {
      try {
        const iframe = document.querySelector('iframe');
        const canvas = await html2canvas(iframe?.contentDocument?.body as HTMLElement, {
          allowTaint: true,
          useCORS: true,
          logging: true,
          imageTimeout: 5000,
        });
        canvas.toBlob(
          async (blob) => {
            if (blob) {
              const file = new File([blob], 'cover.jpg', { type: 'image/jpeg' });
              const { url } = await uploadFileWithToken(file);
              resolve(url || '');
            } else {
              resolve('');
            }
          },
          'image/jpeg',
          0.9
        );
      } catch (error) {
        resolve('');
      }
    });
  }, []);

  const saveProject = async (name?: string) => {
    setIsLoading(true);
    try {
      if (name) {
        await updateProject({ project_id: id as string, project_title: name, code });
        setProjectName(name);
      } else {
        const cover = await getCoverUri();
        await updateProject({ project_id: id as string, project_title: projectName, code, cover });
      }
    } catch (error) {
      console.error('保存项目失败:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCode = async (prompt: string, subcode?: string, fileURLs?: string[]) => {
    setIsLoading(true);
    try {
      const data = await generateProject({
        prompt,
        project_id: id,
        sub_code: subcode,
        file_uri: fileURLs, // 添加 file_uri 参数
      });
      setCode(data.code ?? '');
      setProjectName(data.project_title as string);
      if (data.project_id && data.project_id !== id) {
        navigate(`/project/${data.project_id}`, { replace: true });
      }
    } catch (error) {
      console.error('生成项目失败:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeProject = async () => {
      if (initializeProjectCalled.current || isLoading) return;
      initializeProjectCalled.current = true;
      setIsLoading(true);
      let fileUrls: string[] = [];
      if (files) {
        try {
          fileUrls = JSON.parse(files);
          console.log('解析后的文件 URLs:', fileUrls);
        } catch (error) {
          console.error('解析文件 URL 失败:', error);
        }
      }
      try {
        if (id) {
          const data = await getProjectDetail({ project_id: id });
          setCode(data.code || '');
          setProjectName(data.project_title as string);
        } else if (message) {
          const data = await generateProject({ prompt: message, file_uri: fileUrls });
          setCode(data.code ?? '');
          setProjectName(data.project_title as string);
          if (data.project_id) {
            navigate(`/project/${data.project_id}`, { replace: true });
          }
        }
      } catch (error) {
        console.error('加载项目失败:', error);
        setCode('<h1>加载失败</h1><p>请稍后重试</p>');
      } finally {
        setIsLoading(false);
      }
    };

    initializeProject();
  }, [id, message, navigate]);

  useEffect(() => {
    const fetchProjectHistory = async () => {
      if (!id) return;
      try {
        const { data_list } = await getProjectHistory({ project_id: id });
        setProjectHistory(data_list);
      } catch (error) {}
    };

    fetchProjectHistory();
  }, [id]);

  const handleCodeChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
    }
  };

  return (
    <div className={styles.appContainer}>
      <NavBar
        projectName={projectName}
        saveProject={saveProject}
        view={view}
        setView={setView}
        onLogoClick={() => navigate('/')}
      />
      <main className={styles.mainContent}>
        <EditorPreview view={view} content={code} projectHistory={projectHistory} onContentChange={handleCodeChange} />
      </main>
      <InputArea updateCode={updateCode} />
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
