import { Editor } from '@monaco-editor/react';
import { Maximize2, Minimize2 } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

import styles from './index.module.css';

interface EditorPreviewProps {
  content: string;
  projectHistory: { user_prompt: string; create_at: number }[];
  onContentChange?: (value: string | undefined) => void;
  view: 'preview' | 'code' | 'prompt';
}

const EditorPreview: React.FC<EditorPreviewProps> = ({
  content,
  projectHistory,
  onContentChange,
  view = 'preview',
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (view === 'preview' && iframeRef.current) {
      iframeRef.current.srcdoc = content;
    }
  }, [content, view]);

  const toggleFullscreen = () => {
    if (containerRef.current) {
      containerRef.current.classList.toggle(styles.fullscreenContainer);
      const parent = containerRef.current.parentElement;
      if (parent) {
        parent.style.zIndex = isFullscreen ? '' : '9999';
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const getPromptContent = () => {
    return projectHistory
      .map((item) => `${new Date(item.create_at * 1000).toLocaleString()}\n${item.user_prompt}`)
      .join('\n\n');
  };

  return (
    <div ref={containerRef} className={styles.contentContainer}>
      <div style={{ display: view === 'preview' ? 'block' : 'none', height: '100%' }}>
        <iframe ref={iframeRef} className={styles.contentIframe} title="Preview" style={{ border: 'none' }} />
        <button
          className={styles.expandButton}
          aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
          onClick={toggleFullscreen}
        >
          {isFullscreen ? <Minimize2 className={styles.expandIcon} /> : <Maximize2 className={styles.expandIcon} />}
        </button>
      </div>
      <div style={{ display: view === 'code' ? 'block' : 'none', height: '100%' }}>
        <Editor
          height="100%"
          defaultLanguage="html"
          value={content}
          onChange={onContentChange}
          className={styles.contentEditor}
          options={{
            minimap: { enabled: false },
            fontSize: 14,
            lineNumbers: 'on',
            roundedSelection: false,
            scrollBeyondLastLine: false,
          }}
        />
      </div>
      <div style={{ display: view === 'prompt' ? 'block' : 'none', height: '100%' }}>
        <Editor
          height="100%"
          defaultLanguage="plaintext"
          value={getPromptContent()}
          className={styles.contentEditor}
          options={{
            minimap: { enabled: false },
            fontSize: 14,
            lineNumbers: 'on',
            roundedSelection: false,
            scrollBeyondLastLine: false,
            readOnly: true,
          }}
        />
      </div>
    </div>
  );
};

export default EditorPreview;
