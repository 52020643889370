import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arcoLogo from '~/assets/arco-icon-header.svg';
import userDefaultAvatar from '~/assets/user-default-avatar.svg';
import { AUTH_CONSTANTS } from '~/constants';

interface HeaderProps {
  showUserSection?: boolean;
}

const Header: React.FC<HeaderProps> = ({ showUserSection = false }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userAvatar, setUserAvatar] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (showUserSection) {
      const token = localStorage.getItem(AUTH_CONSTANTS.TOKEN_KEY);
      const userInfo = localStorage.getItem(AUTH_CONSTANTS.USER_INFO_KEY);
      if (token && userInfo) {
        setIsLoggedIn(true);
        const parsedUserInfo = JSON.parse(userInfo);
        setUserAvatar(parsedUserInfo.avatar);
      }
      setIsLoading(false);
    }
  }, [showUserSection]);

  const handleGotoLogin = () => {
    navigate('/login');
  };

  const handleGotoUserInfo = () => {
    navigate('/user');
  };

  return (
    <div style={styles.header}>
      <img src={arcoLogo} alt="Arco Logo" style={styles.logoImage} onClick={() => navigate('/')} />
      {showUserSection &&
        !isLoading &&
        (isLoggedIn ? (
          <img
            onClick={handleGotoUserInfo}
            src={userAvatar.length > 0 ? userAvatar : userDefaultAvatar}
            alt="用户头像"
            style={styles.userAvatar}
          />
        ) : (
          <button onClick={handleGotoLogin} style={styles.loginButton}>
            Sign In
          </button>
        ))}
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '65px',
    padding: '0px 40px',
  },
  logoImage: {
    cursor: 'pointer',
    height: '30px',
    width: '70px',
  },
  loginButton: {
    backgroundColor: '#ff5530',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    padding: '8px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  loginButtonHover: {
    backgroundColor: '#e64a2a',
  },
  userAvatar: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
  },
};

export default Header;
