import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserInfo, logout } from '~/api/account';
import CommonHeader from '~/components/CommonHeader';
import { AUTH_CONSTANTS } from '~/constants';

import UserInfoCard from './components/UserInfoCard';

const UserProfile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({ id: '', user_name: '', user_email: '', avatar: '' });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = localStorage.getItem(AUTH_CONSTANTS.USER_INFO_KEY);
    if (userInfo) {
      setUserInfo(JSON.parse(userInfo));
    }
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await logout();
      localStorage.removeItem(AUTH_CONSTANTS.TOKEN_KEY);
      localStorage.removeItem(AUTH_CONSTANTS.USER_INFO_KEY);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <CommonHeader />
      <UserInfoCard name={userInfo.user_name} email={userInfo.user_email} avatar={userInfo.avatar} />
      <button onClick={handleLogout} style={styles.logoutButton} disabled={isLoading}>
        {isLoading ? 'Logging out...' : 'Logout'}
      </button>
    </div>
  );
};

const styles = {
  logoutButton: {
    backgroundColor: '#ff5530',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    padding: '8px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    display: 'block',
    margin: '20px auto',
  },
};

export default UserProfile;
