import { ArrowUp, FileText, ImageIcon, Paperclip, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

import styles from './index.module.css';

interface InputAreaProps {
  updateCode: (prompt: string, subcode?: string) => Promise<void>;
}

const InputArea: React.FC<InputAreaProps> = ({ updateCode }) => {
  const [showAttachmentOptions, setShowAttachmentOptions] = useState(false);
  const [inputText, setInputText] = useState('');
  const [pastedImage, setPastedImage] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedHTML, setSelectedHTML] = useState<string | null>(null);

  const attachmentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (attachmentRef.current && !attachmentRef.current.contains(event.target as Node)) {
        setShowAttachmentOptions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      const iframe = document.querySelector('iframe') as HTMLIFrameElement;
      if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

        if (iframeDocument) {
          const style = iframeDocument.createElement('style');
          style.textContent = `
            .highlight-hover {
              outline: 2px solid rgba(65, 105, 225, 0.5) !important;
            }
            .highlight-select {
              outline: 2px solid rgba(50, 205, 50, 0.7) !important;
            }
          `;
          iframeDocument.head.appendChild(style);

          let currentHover: HTMLElement | null = null;
          let currentSelect: HTMLElement | null = null;

          const mousemoveHandler = (e: MouseEvent) => {
            if (currentHover) {
              currentHover.classList.remove('highlight-hover');
            }
            currentHover = e.target as HTMLElement;
            currentHover.classList.add('highlight-hover');
          };

          const clickHandler = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            if (currentSelect) {
              currentSelect.classList.remove('highlight-select');
            }
            currentSelect = e.target as HTMLElement;
            currentSelect.classList.add('highlight-select');
            setSelectedHTML(currentSelect.outerHTML);
          };

          iframeDocument.body.addEventListener('mousemove', mousemoveHandler);
          iframeDocument.body.addEventListener('click', clickHandler, true);

          return () => {
            iframeDocument.body.removeEventListener('mousemove', mousemoveHandler);
            iframeDocument.body.removeEventListener('click', clickHandler, true);
            iframeDocument.head.removeChild(style);
            if (currentHover) currentHover.classList.remove('highlight-hover');
            if (currentSelect) currentSelect.classList.remove('highlight-select');
          };
        }
      }
    }
  }, [isEditMode]);

  const toggleAttachmentOptions = () => {
    setShowAttachmentOptions(!showAttachmentOptions);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          setPastedImage(event.target?.result as string);
        };
        reader.readAsDataURL(blob as Blob);
        e.preventDefault();
        break;
      }
    }
  };

  const handleSend = async () => {
    if (inputText.trim() || selectedHTML) {
      try {
        await updateCode(inputText, selectedHTML || undefined);
        setInputText('');
        setSelectedHTML(null);
        setIsEditMode(false);
        // 发送后不清除selectedHTML，保持选中状态
      } catch (error) {
        console.error('Failed to update code:', error);
      }
    }
  };

  const removeImage = () => {
    setPastedImage(null);
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleEditClick = () => {
    if (isEditMode) {
      setSelectedHTML(null);
    }
    setIsEditMode(!isEditMode);
  };

  const handleUpload = async (file: File, type: 'document' | 'image') => {
    // try {
    //   const response = await uploadFile({
    //     file: file,
    //     file_name: file.name,
    //     file_type: file.type,
    //   });

    //   if (response.file_url) {
    //     // 处理上传成功的逻辑，例如将文件URL添加到输入框
    //     setInputText((prevText) => prevText + `\n[${type === 'document' ? '文档' : '图片'}](${response.file_url})`);
    //   } else {
    //     alert('上传失败');
    //   }
    // } catch (error) {
    //   alert('上传出错');
    // }
    setShowAttachmentOptions(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'document' | 'image') => {
    const file = event.target.files?.[0];
    if (file) {
      handleUpload(file, type);
    }
  };

  return (
    <div className={styles.bottomInputContainer}>
      <div className={styles.inputBox}>
        <div className={styles.inputContent}>
          {pastedImage && (
            <div className={styles.imagePreview}>
              <img src={pastedImage} alt="Pasted image" className={styles.previewImage} />
              <button className={styles.removeImage} onClick={removeImage}>
                <X className={styles.removeIcon} />
              </button>
            </div>
          )}
          <div className={styles.inputRow}>
            <div className={styles.attachmentContainer} ref={attachmentRef}>
              <button className={styles.attachmentButton} onClick={toggleAttachmentOptions}>
                <Paperclip className={styles.attachmentIcon} />
              </button>
              {showAttachmentOptions && (
                <div className={styles.attachmentOptions}>
                  <button className={styles.optionButton} onClick={() => fileInputRef.current?.click()}>
                    <FileText className={styles.optionIcon} />
                    <span>文档</span>
                  </button>
                  <button className={styles.optionButton} onClick={() => imageInputRef.current?.click()}>
                    <ImageIcon className={styles.optionIcon} />
                    <span>图片</span>
                  </button>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".doc,.docx,.pdf,.txt,.md,.js,.ts,.py,.html,.css,.json"
                onChange={(e) => handleFileChange(e, 'document')}
              />
              <input
                type="file"
                ref={imageInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'image')}
              />
            </div>
            <textarea
              ref={inputRef}
              placeholder="开始你的创作..."
              className={styles.inputTextarea}
              value={inputText}
              onChange={handleInputChange}
              onPaste={handlePaste}
              rows={1}
            />
            <button
              className={`${styles.sendButton} ${inputText.trim() || pastedImage ? styles.active : ''}`}
              onClick={handleSend}
              disabled={!inputText.trim() && !pastedImage}
            >
              <ArrowUp className={styles.sendIcon} />
            </button>
          </div>
        </div>
      </div>
      <button className={`${styles.editButton} ${isEditMode ? styles.active : ''}`} onClick={handleEditClick}>
        {isEditMode ? '取消' : '编辑'}
      </button>
    </div>
  );
};

export default InputArea;
