import React, { useCallback } from 'react';

import discordIcon from '~/assets/discord-icon.svg';
import googleIcon from '~/assets/google-icon.svg';
import CommonHeader from '~/components/CommonHeader';

import { DiscordLoginUrl, GoogleLoginUrl } from './const';
import styles from './index.module.css';

const LoginPage: React.FC = () => {
  const handleGoogleLogin = useCallback(() => {
    window.location.href = GoogleLoginUrl;
  }, []);

  const handleDiscordLogin = useCallback(() => {
    window.location.href = DiscordLoginUrl;
  }, []);

  return (
    <div className={styles.loginContainer}>
      <CommonHeader />
      <main className={styles.mainContent}>
        <h2 className={styles.title}>Log in to Arco</h2>
        <p className={styles.description}>
          Log in to turn your static designs into code and export your designs to HTML, React, or Vue.
        </p>
        <div className={styles.loginOptions}>
          <button className={styles.loginButton} onClick={handleGoogleLogin}>
            <img src={googleIcon} alt="Google" />
            使用Google继续
          </button>
          <button className={styles.loginButton} onClick={handleDiscordLogin}>
            <img src={discordIcon} alt="Discord" />
            使用Discord继续
          </button>
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
