import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectInfo, getCollectionList, getProjectList } from '~/api/project';
import { uploadFileWithToken } from '~/api/upload';
import acroLogo from '~/assets/arco-logo.svg';
import ic_search_file_n from '~/assets/ic_search_file_n.svg';
import ic_send_d from '~/assets/ic_send_d.svg';
import ic_send_n from '~/assets/ic_send_n.svg';
import CommonHeader from '~/components/CommonHeader';
import SearchInput from '~/components/SearchInput';
import { AUTH_CONSTANTS } from '~/constants';

import AttachmentPopup from './AttachmentPopup';
import EmptyState from './components/EmptyState';
import ItemList from './components/ItemList';
import styles from './index.module.css';

const CategoryTabs: React.FC<{
  categories: Array<{ collection_id: number; collection_name: string }>;
  activeCategory: string;
  onCategoryChange: (category: string) => void;
}> = ({ categories, activeCategory, onCategoryChange }) => {
  const [sliderStyle, setSliderStyle] = useState({});
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    const activeTab = tabsRef.current[categories.findIndex((cat) => cat.collection_name === activeCategory)];
    if (activeTab) {
      setSliderStyle({
        width: `${activeTab.offsetWidth}px`,
        left: `${activeTab.offsetLeft}px`,
      });
    }
  }, [activeCategory, categories]);

  return (
    <div className={styles.categoryTabsContainer}>
      <div className={styles.categoryTabs}>
        {categories.map((category, index) => (
          <button
            key={category.collection_id}
            ref={(el) => (tabsRef.current[index] = el)}
            className={`${styles.categoryTab} ${category.collection_name === activeCategory ? styles.active : ''}`}
            onClick={() => onCategoryChange(category.collection_name)}
          >
            {category.collection_name}
          </button>
        ))}
      </div>
    </div>
  );
};

const HomePage: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [allProjects, setAllProjects] = useState<ProjectInfo[]>([]);
  const [categories, setCategories] = useState<Array<{ collection_id: number; collection_name: string }>>([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);

  const fetchCategories = useCallback(async () => {
    try {
      const cachedCategories = localStorage.getItem(AUTH_CONSTANTS.CACHED_CATEGORIES);
      if (cachedCategories) {
        setCategories(JSON.parse(cachedCategories));
      }

      const data = await getCollectionList({});
      setCategories(data.data_list);
      localStorage.setItem(AUTH_CONSTANTS.CACHED_CATEGORIES, JSON.stringify(data.data_list));
    } catch (error) {
      console.error('获取分类列表失败:', error);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    try {
      const cachedProjects = localStorage.getItem(AUTH_CONSTANTS.CACHED_PROJECTS);
      if (cachedProjects) {
        setAllProjects(JSON.parse(cachedProjects));
        setLoading(false);
      }

      const data = await getProjectList();
      setAllProjects(data.data_list ?? []);
      localStorage.setItem(AUTH_CONSTANTS.CACHED_PROJECTS, JSON.stringify(data.data_list));
    } catch (error) {
      console.error('获取项目列表失败:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchData = async () => {
      await Promise.all([fetchCategories(), fetchProjects()]);
    };

    fetchData();
  }, [fetchCategories, fetchProjects]);

  useEffect(() => {
    if (categories.length > 0 && allProjects.length > 0) {
      // 找到第一个有内容的分类
      const firstCategoryWithContent = categories.find((category) =>
        allProjects.some((project) => project.collection?.collection_id === category.collection_id)
      );

      if (firstCategoryWithContent) {
        setActiveCategory(firstCategoryWithContent.collection_name);
      } else {
        // 如果所有分类都没有内容，默认选择第一个分类
        setActiveCategory(categories[0].collection_name);
      }
    }
  }, [categories, allProjects]);

  const displayedItems = useMemo(() => {
    if (!activeCategory) return [];
    const activeCategoryId = categories.find((cat) => cat.collection_name === activeCategory)?.collection_id;
    return allProjects.filter((project) => project.collection?.collection_id === activeCategoryId);
  }, [activeCategory, categories, allProjects]);

  const handleInputChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const handleCategoryChange = useCallback((category: string) => {
    setActiveCategory(category);
  }, []);

  const handleSend = useCallback(() => {
    if (!inputValue.trim() && uploadedUrls.length === 0) return;
    // navigate(`/project?message=${encodeURIComponent(inputValue)}`);
    console.log('生成项目:', uploadedUrls);
    const queryParams = new URLSearchParams({
      message: inputValue.trim(),
      files: JSON.stringify(uploadedUrls),
    });

    navigate(`/project?${queryParams.toString()}`);
  }, [inputValue, navigate]);

  const handleFileUpload = useCallback((url: string) => {
    console.log('上传成功并设置 URLs:', url);
    setUploadedUrls((prevUrls) => [...prevUrls, url]);
  }, []);

  const handleFork = useCallback((projectId?: string) => {
    console.log('fork project:', projectId);
  }, []);

  return (
    <div className={styles.container}>
      <CommonHeader showUserSection={true} />
      <div className={styles.logoContainer}>
        <img src={acroLogo} alt="acro" />
      </div>
      <SearchInput
        value={inputValue}
        onChange={handleInputChange}
        onSend={handleSend}
        onFileUpload={handleFileUpload}
      />
      {categories.length > 0 ? (
        <CategoryTabs categories={categories} activeCategory={activeCategory} onCategoryChange={handleCategoryChange} />
      ) : null}
      {loading ? (
        <div className={styles.loading}>加载中...</div>
      ) : allProjects.length === 0 ? (
        <EmptyState message="暂无项目，开始创建你的第一个项目吧！" />
      ) : (
        <ItemList items={displayedItems} handleFork={handleFork} />
      )}
    </div>
  );
};

export default HomePage;
