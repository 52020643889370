import React from 'react';

import styles from './index.module.css';

const EmptyState: React.FC<{ message: string }> = ({ message }) => (
  <div className={styles.emptyState}>
    <p>{message}</p>
  </div>
);

export default EmptyState;
