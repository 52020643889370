import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { login, mockLogin } from '~/api/account';
import { AUTH_CONSTANTS } from '~/constants';

const LoginCallback: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // 新增 loading 状态
  const location = useLocation();
  const navigate = useNavigate();
  const { platform } = useParams<{ platform: string }>();
  const authAttempted = useRef(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (code && platform && !authAttempted.current) {
      authAttempted.current = true;
      handleAuth(code, platform);
    } else if (!code || !platform) {
      setError('未找到授权码或平台信息');
    }
  }, [location, platform]);

  const handleAuth = async (code: string, platform: string) => {
    if (loading) return; // 如果正在加载，直接返回
    setLoading(true); // 设置为加载状态
    console.log('handleAuth', code, platform);
    const response = await login({
      code: code,
      type: platform,
      redirect_url:
        window.location.hostname === 'localhost' ? `http://localhost:3000/auth/callback/${platform}` : undefined,
    }).catch((e) => {
      console.log('login fail: ', e);
      setError(e);
    });
    setLoading(false); // 加载完成，重置状态
    if (response) {
      console.log('login success', response);
      localStorage.setItem(AUTH_CONSTANTS.TOKEN_KEY, response.token);
      localStorage.setItem(AUTH_CONSTANTS.USER_INFO_KEY, JSON.stringify(response.user_info));
      navigate('/');
    } else {
      setError('登录返回为空');
    }
  };

  if (error) {
    return <div style={styles.errorContainer}>错误: {error}</div>;
  }

  return (
    <div style={styles.loadingContainer}>
      <div style={styles.spinner}></div>
      <p style={styles.loadingText}>正在登录，请稍候...</p>
    </div>
  );
};

const styles = {
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  spinner: {
    width: '50px',
    height: '50px',
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #ff5530',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#ff5530',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontSize: '18px',
    color: '#ff4d4f',
    backgroundColor: '#fff1f0',
  },
};

// 添加关键帧动画
const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// 创建一个 style 元素并将其添加到 head 中
const styleElement = document.createElement('style');
styleElement.innerHTML = spinKeyframes;
document.head.appendChild(styleElement);

export default LoginCallback;
