import React from 'react';

import userDefaultAvatar from '~/assets/user-default-avatar.svg';

interface UserInfoProps {
  name: string;
  email: string;
  avatar: string;
}

const UserInfoCard: React.FC<UserInfoProps> = ({ name, email, avatar }) => {
  return (
    <div style={styles.container}>
      <img src={avatar.length > 0 ? avatar : userDefaultAvatar} alt="用户头像" style={styles.avatar} />
      <div style={styles.name}>{name}</div>
      <div style={styles.email}>{email}</div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  avatar: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
  },
  name: {
    marginTop: '8px',
    overflow: 'hidden',
    color: '#000',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontFamily: 'SF Pro',
    fontSize: '32px',
    fontWeight: '590',
    lineHeight: 'normal',
  },
  email: {
    color: 'rgba(0, 0, 0, 0.50)',
    fontFamily: 'PingFang SC',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
  },
};

export default UserInfoCard;
