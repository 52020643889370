import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/Login';
import LoginCallbackPage from './pages/LoginCallback';
import ProjectPage from './pages/Project';
import UserProfile from './pages/User';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/project/:id" element={<ProjectPage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user" element={<UserProfile />} />
        <Route path="/auth/callback/:platform" element={<LoginCallbackPage />} />
      </Routes>
    </Router>
  );
}

export default App;
