import { AUTH_CONSTANTS } from '~/constants';

import request from './index';

// 登录请求参数接口
interface LoginParams {
  code: string;
  type: string;
  redirect_url?: string;
}

// 用户信息接口
export interface UserInfo {
  id: string;
  user_name: string;
  avatar: string;
  user_email: string;
}

// 登录响应数据接口
interface LoginResponse {
  token: string;
  user_info: UserInfo;
}

// 模拟延迟
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// 模拟登录函数
export async function mockLogin(params: LoginParams): Promise<LoginResponse> {
  // 模拟网络延迟
  await delay(1000);

  // 模拟登录成功
  return {
    token: 'mock_token_' + Math.random().toString(36).substr(2, 9),
    user_info: {
      id: '1',
      user_name: params.type === 'google' ? 'Google Mock User' : 'Discord Mock User',
      avatar: `https://api.dicebear.com/6.x/avataaars/svg?seed=${Math.random()}`,
      user_email: `user_${Math.random().toString(36).substr(2, 5)}@example.com`,
    },
  };
}

// 模拟获取用户信息函数
export async function mockGetUserInfo(): Promise<UserInfo> {
  // 模拟网络延迟
  await delay(500);

  return {
    id: '1',
    user_name: 'Mock User',
    avatar: `https://api.dicebear.com/6.x/avataaars/svg?seed=${Math.random()}`,
    user_email: `user_${Math.random().toString(36).substr(2, 5)}@example.com`,
  };
}

export async function login(params: LoginParams): Promise<LoginResponse> {
  return request<LoginResponse>('/api/account/login', {
    method: 'POST',
    data: params,
  });
}

export async function getUserInfo(): Promise<UserInfo> {
  const response = await request<{ user_info: UserInfo }>('/api/account/user/info', {
    method: 'POST',
  });
  return response.user_info;
}

export async function logout(): Promise<void> {
  return request<void>('/api/account/logout', {
    method: 'POST',
  });
}
